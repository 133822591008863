import { useRouter } from 'next/router';
import Image from 'next/image';

import { useMoengageEventTracker } from '../../hooks/useMoengage';
import useSourceScreen from '../../hooks/useSourceScreen';

import styles from '../../styles/Header.module.scss';

function Categories({ isShowCategories, categories, onBlur }) {
  const router = useRouter();
  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();

  const categoryClick = (elem) => {
    try {
      trackMoengageEvent('plp_visited', {
        source_screen: screen,
        position: 'top',
        category_selected: elem?.category_name,
        screen_name: 'explore exams',
      });
    } catch (error) {
      /* empty */
    }
    router.push({
      pathname: `/course-type/all/${elem?.category_slug}`,
      // query: { state: `${elem?.category_slug}` },
    });
  };

  return (
    <div
      onClick={onBlur}
      className={isShowCategories ? styles.catgories_wrapper_visible : styles.catgories_wrapper_hidden}
    >
      {isShowCategories && (
        <div className={styles.category_content_wrapper} onMouseLeave={onBlur}>
          {categories?.map((category) => (
            <div className={styles.category} key={category?.id} onClick={() => categoryClick(category)}>
              <Image src={category?.category_img} height={32} width={32} alt="Category" />
              <span>{category?.category_name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
export default Categories;
